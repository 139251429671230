import { FAQDataItem } from "../types/FAQTypes";
import { PageDescriptionProps } from "../types/PageDescriptionTypes";

// Steps
import downloadImage from "../assets/svg/download.svg";
import checkmarkImage from "../assets/svg/checkmark.svg";
import footprintImage from "../assets/svg/Footprint.svg";
import statsImage from "../assets/svg/Stats.svg";
import connectImage from "../assets/svg/connect.svg";

// Import SVG icons
import historicalOverviewIcon from "../assets/svg/Scroll.svg";
import dailyReadingMetricsIcon from "../assets/svg/Calendar.svg";
import bookManagementIcon from "../assets/svg/books.svg";
import syncIcon from "../assets/svg/Cloud.svg";
import layoutIcon from "../assets/svg/TextLayout.svg";
import bookmarkIcon from "../assets/svg/Bookmarks.svg";
import recommendationIcon from "../assets/svg/Star.svg";
import searchIcon from "../assets/svg/Search.svg";

// Import phone mockups
import statsMockup from "../assets/app_screenshots/OwOl_Photo_Stats.png";
import libraryMockup from "../assets/app_screenshots/OwOl_Photo_Library_v2.png";
import customizeMockup from "../assets/app_screenshots/OwOl_Photo_Reader_Layout.png";
import discoverMockup from "../assets/app_screenshots/OwOl_Photo_Marketplace.png";
import { StepCardProps } from "../types/StepTypes";

export const faqData: FAQDataItem[] = [
  {
    question: "Does OwOl have free books?",
    answer:
      "Yes, OwOl offers a wide selection of free books in our constantly expanding library, giving you access to a diverse range of stories and topics at no cost. You can easily find them by sorting by price in the marketplace on our app.",
  },
  {
    question: "Can I read books offline?",
    answer:
      "Absolutely! OwOl allows you to download books directly to your device, enabling you to enjoy your reads offline, anytime and anywhere. Initial signup requires Wi-Fi, but once signed in, you'll remain logged in until you choose to log out, ensuring easy access to your library without continuous internet connectivity.",
  },
  {
    question: "How can I purchase books on OwOl?",
    answer:
      "Purchasing books on OwOl is easy and flexible. You can use our 1-tap quick buy option for immediate purchases, or add books to your cart and checkout at your convenience. Both options are designed to enhance your shopping experience on the app.",
  },
  {
    question: "What types of books does OwOl offer?",
    answer:
      "OwOl offers a diverse range of genres, from Romance to Mystery and many more. New stories and genres are being added by authors every day, continually enriching our library and giving you fresh content to explore.",
  },
  {
    question: "How can I participate in the OwOl community?",
    answer:
      "Dive into our vibrant Discord community to discuss your favorite books, share reviews, and connect with fellow readers and authors. It's the perfect place to enhance your reading experience and join lively literary conversations. Become a part of the OwOl community today and contribute to our growing world of book enthusiasts!",
  },
  {
    question: "What should I do if I encounter an issue with the app?",
    answer:
      "If you experience any technical issues, please don't hesitate to reach out to our support team by emailing customersupport@OwOl.ca. Alternatively, you can report the problem directly through the app by navigating to Settings -> More Info -> Send Feedback. We're here to help ensure your reading experience is seamless.",
  },
];

export const pageDescriptions: PageDescriptionProps[] = [
  {
    title: "Your Reading, Quantified",
    description:
      "Enhance your reading experience with OwOl's comprehensive statistics dashboard. Keep a close eye on your daily reading habits, your current reading list, and your overall progress. Our reading streak function and detailed graphs provide insightful breakdowns, encouraging you to reach new heights in your reading journey. It's simple to set goals and track your achievements with OwOl.",
    phoneMockup: statsMockup,
    rectangleColor: "#F6AE2D",
    backgroudColor: "#FDECCE",
    imageOnLeft: false,
    subPoints: [
      {
        icon: historicalOverviewIcon,
        subtitle: "Historical Overview",
        description:
          "Effortlessly review your historical reading patterns and track your literary journey over time.",
      },
      {
        icon: dailyReadingMetricsIcon,
        subtitle: "Daily Reading Metrics",
        description:
          "Visualize daily reading in detailed graphs and numerical breakdowns.",
      },
    ],
  },
  {
    title: "Your Personal Library at Your Fingertips",
    description:
      "Navigate your collection effortlessly with OwOl's intuitive Library feature. Browse your books, track your reading progress visually, and access all associated content, including images and summaries, with ease. Enjoy seamless syncing across all your devices, ensuring your library and progress are always up-to-date, no matter where you read.",
    phoneMockup: libraryMockup,
    rectangleColor: "#A37A74",
    backgroudColor: "#EBE2E0",
    imageOnLeft: true,
    subPoints: [
      {
        icon: bookManagementIcon,
        subtitle: "Seamless Book Management",
        description:
          "Sort, filter, and manage your books with ease. View your entire collection and access your reading list whenever you need.",
      },
      {
        icon: syncIcon,
        subtitle: "Sync Across Devices",
        description:
          "Your reading experience is unified across all your devices. Start reading on one device and pick up right where you left off on another, seamlessly.",
      },
    ],
  },
  {
    title: "Customize Your Reading Experience",
    description:
      "Tailor every aspect of your reading environment with OwOl's eBook reader. Adjust fonts, background colors, and text alignment to suit your reading preferences. Enjoy seamless navigation and automatic progress saving for a truly personalized reading journey.",
    phoneMockup: customizeMockup,
    rectangleColor: "#3872A5",
    backgroudColor: "#DCE3E8",
    imageOnLeft: false,
    subPoints: [
      {
        icon: layoutIcon,
        subtitle: "Flexible Reading Layout",
        description:
          "Tailor your layout with a range of customization options from font size to line spacing, creating a reading environment that best suits your comfort and style.",
      },
      {
        icon: bookmarkIcon,
        subtitle: "Bookmarking",
        description:
          "Easily create bookmarks to mark important passages and automatically save your reading progress. Never lose your place again.",
      },
    ],
  },
  {
    title: "Discover More with Every Tap",
    description:
      "Explore a vibrant marketplace filled with books from a variety of genres and authors. Whether you're into thrilling mysteries, heartfelt romances, or enlightening non-fiction, OwOl has a rich array of choices for every reader. Dive into our user-friendly interface and start your search for your next great read today!",
    phoneMockup: discoverMockup,
    rectangleColor: "#63A375",
    backgroudColor: "#DAE8DE",
    imageOnLeft: true,
    subPoints: [
      {
        icon: recommendationIcon,
        subtitle: "Personalized Recommendations",
        description:
          "Discover books tailored to your taste. Our smart recommendation engine suggests titles based on your reading habits and preferences, along with related books that complement your interests.",
      },
      {
        icon: searchIcon,
        subtitle: "Advanced Search Options",
        description:
          "Unearth hidden gems with our sophisticated search tools. Filter by price, rating, genre, and format to pinpoint exactly what you're looking for in our expansive library. Dive deep into our selection and discover books that match your precise preferences.",
      },
    ],
  },
];

export const stepsData: StepCardProps[] = [
  {
    stepNumber: 1,
    title: "Download the app",
    description:
      "Get started by downloading OwOl from the Google Play Store. A world of books awaits at your fingertips.",
    imageSrc: downloadImage,
  },
  {
    stepNumber: 2,
    title: "Choose a book",
    description:
      "Explore our constantly growing library, packed with unique stories from independent authors and popular bestsellers. Select your next great read with personalized recommendations tailored to your tastes.",
    imageSrc: checkmarkImage,
  },
  {
    stepNumber: 3,
    title: "Start your journey",
    description:
      "Open your chosen book and dive into the story. Adjust your reading settings for a personalized experience.",
    imageSrc: footprintImage,
  },
  {
    stepNumber: 4,
    title: "Track your progress",
    description:
      "Manage your reading progress with precision—track your books, set personal goals, and bookmark key moments. OwOl enhances every reading session.",
    imageSrc: statsImage,
  },
  {
    stepNumber: 5,
    title: "Support Indie Authors",
    description:
      "Engage directly with authors—post reviews, follow their work, and join vibrant discussions in our growing Discord community.",
    imageSrc: connectImage,
  },
];
