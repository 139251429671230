import React from "react";
import "./Hero.css";
import googlePlayBadge from "../../../assets/svg/google-play-badge.svg";
import appScreenshots from "../../../assets/app_screenshots/OwOl_Photo_Demo_v2_withShadow.png";

const Hero: React.FC = () => {
  return (
    <div className="hero-container">
      <section className="hero">
        <div className="hero-content">
          <h1>Start your reading journey</h1>
          <p>
            Step into a vibrant marketplace of indie books with OwOl. Buy, sell,
            and read unique stories from emerging authors, and immerse yourself
            in a community that celebrates creativity and artistic freedom.
          </p>
          <a
            href="https://play.google.com/store/apps/details?id=ca.owol.owolreader"
            className="download-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googlePlayBadge} alt="Get it on Google Play" />
          </a>
        </div>
        <div className="hero-image">
          <img src={appScreenshots} alt="OwOl app screenshots" />
        </div>
      </section>
    </div>
  );
};

export default Hero;
