import React, { useEffect, useRef } from "react";
import { stepsData } from "../../../data";
import useBackgroundColorChange from "../../../hooks/backgroundColorChangeHook";
import StepCard from "../../common/StepCard/StepCard";

import "./Steps.css";

const Steps: React.FC = () => {
  const stepsRef = useRef<HTMLDivElement>(null);
  const changeBackgroundColor = useBackgroundColorChange();

  useEffect(() => {
    const observerTarget = stepsRef.current; // Store the current value in a variable
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          changeBackgroundColor("#FFFFFF");
        }
      },
      { threshold: 0.4 }
    );

    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [changeBackgroundColor]);

  return (
    <div className="steps-container" ref={stepsRef}>
      <h1 className="step-main-title">Effortless Reading Awaits</h1>
      <h2 className="step-sub-title">
        Reading reimagined for the modern book lover.
      </h2>
      <div className="step-cards">
        {stepsData.map((step) => (
          <StepCard
            key={step.stepNumber}
            stepNumber={step.stepNumber}
            title={step.title}
            description={step.description}
            imageSrc={step.imageSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default Steps;
