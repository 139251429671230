import React from "react";

import "./Info.css";

import { pageDescriptions } from "../../../data";
import PageDescription from "../../common/PageDescription/PageDescription";

const Info: React.FC = () => {
  return (
    <div className="info-container">
      {pageDescriptions.map((props, index) => (
        <PageDescription key={index} {...props} />
      ))}
    </div>
  );
};

export default Info;
