import React from "react";
import "./DownloadAppButton.css";

const DownloadAppButton = () => {
  const handleClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=ca.owol.owolreader",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <button className="download-app-button" onClick={handleClick}>
      Download App
    </button>
  );
};

export default DownloadAppButton;
