import React from "react";
import "./NavBar.css";
import logo from "../../../assets/Logo/OwOl Logo Wide Black.png"; // Update this path
import DownloadAppButton from "../../common/DownloadAppButton/DownloadAppButton";

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <img src={logo} alt="OwOl Logo" />
        </div>
        <DownloadAppButton />
      </div>
    </nav>
  );
};

export default NavBar;
