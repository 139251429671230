import React from "react";
import { StepCardProps } from "../../../types/StepTypes";
import "./StepCard.css";

const StepCard: React.FC<StepCardProps> = ({
  stepNumber,
  title,
  description,
  imageSrc,
}) => {
  return (
    <div className="step-card-component">
      <div className="icon-container">
        <img src={imageSrc} alt={`Step ${stepNumber}`} className="step-icon" />
      </div>
      <div className="content">
        <p className="step-text">Step {stepNumber}:</p>
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default StepCard;
