import React, { useEffect, useRef } from "react";
import { faqData } from "../../../data/index";
import useBackgroundColorChange from "../../../hooks/backgroundColorChangeHook";
import FAQItem from "../../common/FAQItem/FAQItem";
import "./Faq.css";

const FAQ: React.FC = () => {
  const stepsRef = useRef<HTMLDivElement>(null);
  const changeBackgroundColor = useBackgroundColorChange();

  useEffect(() => {
    const observerTarget = stepsRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          changeBackgroundColor("#FFFFFF");
        }
      },
      { threshold: 0.4 }
    );

    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [changeBackgroundColor]);

  return (
    <div className="faq-container" ref={stepsRef}>
      <h1>FAQs</h1>
      <p className="faq-description">
        Find detailed answers to your questions here. We strive to respond
        swiftly to all inquiries. For more immediate assistance, feel free to
        ask questions in our Discord community or email us directly at
        customer.support@OwOl.ca for further information and support.
      </p>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
