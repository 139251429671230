import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import "./PageDescription.css";
import useBackgroundColorChange from "../../../hooks/backgroundColorChangeHook";
import { PageDescriptionProps } from "../../../types/PageDescriptionTypes";

const PageDescription: React.FC<PageDescriptionProps> = ({
  title,
  description,
  phoneMockup,
  backgroudColor,
  rectangleColor,
  subPoints,
  imageOnLeft,
}) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isPartiallyInView = useInView(ref, { amount: 0.8 });
  const isCompletelyOutOfView = useInView(ref, { amount: 0 });

  useEffect(() => {
    if (isPartiallyInView) {
      controls.start("visible");
    } else if (!isCompletelyOutOfView) {
      controls.start("hidden");
    }
  }, [controls, isPartiallyInView, isCompletelyOutOfView]);

  const changeBackgroundColor = useBackgroundColorChange();

  useEffect(() => {
    const observerTarget = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          changeBackgroundColor(backgroudColor);
        }
      },
      { threshold: 0.3 }
    );

    if (observerTarget) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [changeBackgroundColor, backgroudColor]);

  return (
    <div
      className={`page-description ${
        imageOnLeft ? "image-left" : "image-right"
      }`}
      ref={ref}
    >
      <div className="pd-content">
        <h1 className="pd-title">{title}</h1>
        <p className="pd-description">{description}</p>
        <div className="pd-sub-points">
          {subPoints.map((point, index) => (
            <div key={index} className="pd-sub-point">
              <img src={point.icon} alt={point.subtitle} className="icon" />
              <div className="pd-sub-point-text">
                <h3 className="pd-subtitle">{point.subtitle}</h3>
                <p className="pd-sub-description">{point.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pd-phone-container">
        <motion.div
          className="pd-background-rectangle"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { x: 0, y: 0 },
            visible: { x: 10, y: 15 },
          }}
          transition={{ duration: 0.4, ease: "easeOut" }}
          style={{ backgroundColor: rectangleColor }}
        />
        <img src={phoneMockup} alt="Phone Mockup" className="pd-phone-mockup" />
      </div>
    </div>
  );
};

export default PageDescription;
