import { useCallback, useEffect } from "react";

const useBackgroundColorChange = (initialColor: string = "#ffffff") => {
  const changeBackgroundColor = useCallback((newColor: string) => {
    document.documentElement.style.setProperty("--background-color", newColor);
  }, []);

  useEffect(() => {
    changeBackgroundColor(initialColor);
  }, [initialColor, changeBackgroundColor]);

  return changeBackgroundColor;
};

export default useBackgroundColorChange;
