import React from "react";

import NavBar from "../../layout/NavBar/NavBar";
import Hero from "../../layout/Hero/Hero";
import Steps from "../../layout/Steps/Steps";
import Info from "../../layout/Info/Info";
import Faq from "../../layout/FAQ/Faq";
import Footer from "../../layout/Footer/Footer";
import useBackgroundColorChange from "../../../hooks/backgroundColorChangeHook";

import "./Home.css";

const Home: React.FC = () => {
  useBackgroundColorChange("#ffffff");

  return (
    <div className="home-wrapper">
      <title>OwOl</title>
      <NavBar />
      <div className="main-content-wrapper">
        <Hero />
        <Steps />
        <Info />
        <Faq />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
