import React from "react";
import "./Footer.css";
import googlePlayBadge from "../../../assets/svg/google-play-badge.svg";
import logo from "../../../assets/Logo/OwOl Logo Wide Black.png";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/svg/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/svg/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/svg/linkedin-icon.svg";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-dark">
        <div className="footer-content footer-dark-content">
          <div className="footer-text">
            <h2 className="footer-heading">Ready to unleash your mind?</h2>
            <p className="footer-subheading">
              Try OwOl today and begin your journey through endless stories and
              fascinating worlds.
            </p>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=ca.owol.owolreader"
            className="download-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googlePlayBadge} alt="Get it on Google Play" />
          </a>
        </div>
      </div>
      <div className="footer-light">
        <div className="footer-content">
          <div className="footer-bottom">
            <div className="footer-logo">
              <img src={logo} alt="OwOl Logo" />
            </div>
            <div className="footer-social-icons">
              <a
                href="https://www.facebook.com/OwOlWriter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/owolwriter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://x.com/OwOlWriter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/99065681/admin/dashboard/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
            </div>
          </div>
          <div className="footer-copyright">
            © 2024 OwOl Inc. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
