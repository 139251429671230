import React from "react";
import Home from "./components/pages/Home/Home";

import "./App.css";
import "./styles/Global.css";
import "./styles/Fonts.css";
import "./styles/Variables.css";

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
